import { createRouter, createWebHistory } from 'vue-router';

import HomePage from "@/pages/HomePage.vue";
import GroupPage from "@/pages/GroupPage.vue";
import ProductPage from "@/pages/ProductPage.vue";
import BasketPage from "@/pages/BasketPage.vue";
import CheckoutPage from "@/pages/CheckoutPage.vue";
import FAQPage from "@/pages/FAQPage.vue";
import SuccessPage from "@/pages/SuccessPage.vue";
import MyOrdersPage from "@/pages/MyOrdersPage.vue";
import StaffSuccessPage from "@/pages/StaffSuccessPage.vue";
import BillingPage from "@/pages/billingPage.vue";
import SuccessBalancePage from "@/pages/SuccessBalancePage.vue";

const routes = [
    { path: "/products/:groupId/:productId", component: ProductPage, props: true },
    { path: "/products/:groupId", component: GroupPage, props: true },
    { path: "/basket", component: BasketPage, props: true },
    { path: "/success/staff/:guid/:key", component: StaffSuccessPage, props: true },
    { path: "/success", component: SuccessPage, props: true },
    { path: "/balance-success", component: SuccessBalancePage, props: true },
    { path: "/my-order", component: MyOrdersPage, props: true },
    { path: "/checkout", component: CheckoutPage, props: true },
    { path: "/FAQ", component: FAQPage, props: true },
    { path: "/payment/:guid", component: BillingPage, pros: true },
    { path: "/", component: HomePage },
]



const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;