<template>
    <BasePage>
        <div class="container mb-7">
            <div v-for="faq in faqs" v-bind:key="faq.faqId" class="card mt-5">
                <div class="card-header">
                    <h2 class="card-title">{{faq.faqTitle}}</h2>
                </div>
                <div class="card-body">
                    {{faq.faqText}}
                </div>
            </div>
        </div>
    </BasePage>
</template>

<script>
import api from '@/services/api'


import BasePage from '@/components/BasePage.vue';  // Adjust the import path if necessary

export default {
    name: 'FAQPage',
    components: {
        BasePage,
    },
    data() {
        return {
            faqs: []
        }
    },
    async mounted() {
        const faqRes = await api.get('/api/faq')
        this.faqs = faqRes.data;
        document.title = "FAQ | Larkhall Butchers"
    }
};
</script>
