<template>
    <BasePage>
        <div class="container mt-6 mb-7 text-center">
            <div class="row justify-content-center">
                <div class="col-auto w-75">
                    <img width="100" src="/LarkhallOrderConfirmed.svg">
                    <h1 class="mt-6">Order Confirmed</h1>
                    <p>Please check your email for an order confirmation.</p>
                    <div class="card mt-6">
                        <div class="card-header">
                            <h2 class="card-title">Order N<sup>o</sup> {{ orderNo }}</h2>
                            <div class="card-options">
                                Paid in store
                            </div>
                        </div>
                        <div class="card-body">
                            <table class="card-table table">
                                <thead>
                                    <th>
                                        Item
                                    </th>
                                    <th>
                                        Description
                                    </th>
                                    <th>
                                        Cost
                                    </th>
                                </thead>
                                <tbody>
                                <tr v-for="item in stripeItems" :key="item.price_data.product_data.name">
                                    <td>
                                        {{item.price_data.product_data.name}}
                                    </td>
                                    <td>
                                        {{item.price_data.product_data.description}}
                                    </td>
                                    <td>
                                        {{ formatCurrency(item.price_data.unit_amount) }}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="card-footer">
                            <h2 class="h2">
                                Total: {{ formatCurrency(stripeTotal) }}
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </BasePage>
</template>

<script>


import BasePage from '@/components/BasePage.vue';  // Adjust the import path if necessary
import api from '@/services/api'

export default {
    name: 'SuccessPage',
    components: {
        BasePage,
    },
    data() {
        return {
            stripeItems: [],
            orderNo: [],
            stripeTotal: 0
        }
    },
    methods: {
        formatCurrency(amountInPence) {
            const pounds = amountInPence / 100;
            return new Intl.NumberFormat('en-GB', {style: 'currency', currency: 'GBP'}).format(pounds);
        },
    },
    async mounted() {
        try {
            localStorage.removeItem('basketId')
            const res = await api.get(`/api/orders/staff/${this.$route.params.guid}/${this.$route.params.key}`)
            this.stripeItems = JSON.parse(res.data.stripeItems);
            this.orderNo = res.data.id;
            this.stripeTotal = res.data.stripeTotal
        } catch (e) {
            console.log(e)
        }
        document.title = "Order Confirmed | Larkhall Butchers"
    }
};
</script>

<script setup>
</script>