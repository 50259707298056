<template>
    <div class="d-flex flex-column min-vh-100">
        <div>
            <header class="navbar navbar-expand-md d-print-none flex-grow-0">
                <div class="container-xl">
                    <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbar-menu" aria-controls="navbar-menu" aria-expanded="false"
                            aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <a class="navbar-brand" href="/">
                        <img height="50" src="/larkhall.png"><span class="d-none d-sm-block">Christmas Orders</span>
                    </a>
                    <div class="nav-item">
                        <a href="/basket/" class="nav-link d-flex lh-1 text-reset p-0" aria-label="Shopping Cart">
                            <span class="avatar">
                                <IconShoppingCart></IconShoppingCart>
                            </span>
                        </a>
                    </div>
                </div>
            </header>
            <header class="navbar-expand-md">
                <div class="collapse navbar-collapse" id="navbar-menu">
                    <div class="navbar">
                        <div class="container-xl">
                            <div class="row flex-fill align-items-center">
                                <div class="col">
                                    <MenuItems></MenuItems>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>

        <slot class="flex-grow-1"></slot>

        <footer class="footer  bg-primary text-primary-fg d-print-none">
            <div class="container-xl">
                <div class="row text-center align-items-center flex-row-reverse">
                    <div class="col-lg-auto ms-lg-auto">
                        <ul class="list-inline list-inline-dots mb-0">
                            <li class="list-inline-item"><a href="https://larkhallbutchers.co.uk/privacy-policy" target="_blank"
                                                            class="link-secondary text-primary-fg" rel="noopener">Privacy Policy</a></li>
                            <li class="list-inline-item"><a href="/FAQ" target="_blank"
                                                            class="link-secondary text-primary-fg" rel="noopener">FAQ</a></li>
                            <li class="list-inline-item"><a href="/my-order"
                                                            class="link-secondary text-primary-fg" rel="noopener">My Order</a></li>
                        </ul>
                    </div>
                    <div class="col-12 col-lg-auto mt-3 mt-lg-0">
                        <ul class="list-inline list-inline-dots mb-0">
                            <li class="list-inline-item">
                                Copyright © 2023
                                Larkhall Butchers
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>
<script>
import MenuItems from "@/components/MenuItems.vue"
import {IconShoppingCart} from "@tabler/icons-vue"

export default {
    name: "BasePage",
    components: {MenuItems, IconShoppingCart},
}
</script>