<template>
    <BasePage>
        <div class="container mt-6 mb-7 text-center">
            <div class="row justify-content-center">
                <div class="col-auto">
                    <img width="300" src="/LarkhallOrderConfirmed.svg">
                    <h1 class="mt-6">Order Confirmed</h1>
                    <p>Please check your email for an order confirmation.</p>
                </div>
            </div>
        </div>
    </BasePage>
</template>

<script>


import BasePage from '@/components/BasePage.vue';  // Adjust the import path if necessary

export default {
    name: 'SuccessPage',
    components: {
        BasePage,
    },
    mounted() {
        localStorage.removeItem('basketId')
        document.title = "Order Confirmed | Larkhall Butchers"
    }
};
</script>
