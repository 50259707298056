<template>
    <BasePage>
        <div class="container">
            <ProductDetails></ProductDetails>
            <FBT></FBT>
        </div>
    </BasePage>
</template>

<script>
document.title = "Chirstmas Orders | Larkhall Butchers"
import BasePage from "@/components/BasePage.vue";
import ProductDetails from "@/components/ProductDetails.vue";
import FBT from "@/components/FBTComponent.vue";
document.title = "Chirstmas Orders | Larkhall Butchers"
export default {
    components: {
        FBT,
        ProductDetails,
        BasePage
    }
}
</script>