<template>
    <BasePage ref="basePage">
        <div class="container">
            <div class="d-sm-block d-md-none mt-3">
            </div>
            <ProductTiles :products="products"></ProductTiles>
        </div>
    </BasePage>
</template>

<script>
import ProductTiles from "@/components/ProductTiles.vue";

document.title = "Christmas Orders | Larkhall Butchers"
import BasePage from '@/components/BasePage.vue';  // Adjust the import path if necessary
import api from '@/services/api'

export default {
    name: 'HomePage',
    components: {
        ProductTiles,
        BasePage,
    },
    data() {
        return {
            products: null,
            allProducts: null,
            types: null
        }
    },
    created() {
        this.getProducts();
    },
    methods: {
        async getProducts() {
            const [groupsRes, typesRes, productsRes,] = await Promise.all([
                api.get('/api/groups'),
                api.get('/api/types'),
                api.get('/api/products'),
            ]);
            const groups = groupsRes.data;
            const groupId = groups.find(g => g.groupSlug === this.$route.params.groupId).groupId
            console.log(groupId);
            //this.products = productsRes.data.filter(p => p.group === groupId);
            this.allProducts = productsRes.data.filter(p => p.group === groupId);
            this.types = typesRes.data;
            if(this.$route.query.type) {
                this.products = this.allProducts.filter(p => p.type === this.types.find(t => t.typeSlug === this.$route.query.type).typeId);
            } else {
                this.products = this.allProducts
            }

        }
    },
    watch: {
        '$route.query.type': function(newValue) {
            if(newValue) {
                this.products = []
                this.products = this.allProducts.filter(p => p.type === this.types.find(t => t.typeSlug === newValue).typeId);
            }
        }
    }
};
</script>
