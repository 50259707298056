<template>
    <div class="card mt-5 mb-5">
        <div class="card-header">
            <h2 class="card-title">
                Basket
            </h2>
            <div v-if="checkout" class="card-options">
               <a href="./basket/" class="btn btn-primary">Edit</a>
            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md col-sm-12">
                    <div class="alert alert-danger" role="alert" v-if="basket.length === 0">
                        <div class="d-flex">
                            <div>
                                <!-- Download SVG icon from http://tabler-icons.io/i/alert-circle -->
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon alert-icon" width="24" height="24"
                                     viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                     stroke-linecap="round"
                                     stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
                                    <path d="M12 8v4"></path>
                                    <path d="M12 16h.01"></path>
                                </svg>
                            </div>
                            <div>
                                Your basket is empty
                            </div>
                        </div>
                    </div>
                    <div class="card mb-1" v-for="item in basket" :key="item.basketItemId">
                        <div class="row row-0">
                            <div class="col-2 d-none d-md-block" v-if="!checkout">
                                <img :alt="'Image of ' + item.basketItem.name" :src="item.basketItem.image"
                                     class="w-100 h-100 object-cover card-img-start"/>
                            </div>
                            <div class="col-md col-sm-10">
                                <div class="card-body">
                                    <h3 class="card-title">{{ item.basketItem.item }} <span v-if="!item.isAvailable" class="my-3 text-danger"><IconAlertCircle></IconAlertCircle> Out of stock</span> </h3>
                                    <p class="text-secondary">
                                        <!--{{ item }}-->
                                        <span v-if="item.basketItem.unit !== 'each'">
                      {{ item.basketItem.selectedWeight }}{{
                                            item.basketItem.unit
                                            }} @ {{ formatCurrency(item.basketItem.price) }} / {{
                                            item.basketItem.unit
                                            }} -
                      <span
                              v-if="item.basketItem.discountAmount > 0 && item.basketItem.selectedWeight >= item.basketItem.discountQty">
                          <s>{{
                              formatCurrency(item.basketItem.selectedWeight * item.basketItem.price)
                              }}</s> {{
                          formatCurrency((item.basketItem.selectedWeight * item.basketItem.price) * ((100 - item.basketItem.discountAmount) / 100))
                          }}
                      </span>
                      <span
                              v-if="item.basketItem.discountAmount === 0 || item.basketItem.selectedWeight < item.basketItem.discountQty">
                      {{ formatCurrency(item.basketItem.selectedWeight * item.basketItem.price) }}
                      </span>
                                            <span v-if="item.basketItem.deposit"> - ( {{formatCurrency(item.basketItem.deposit)}} deposit )</span>

                      <br/>
                      <span v-for="(cust, index) in item.basketItem.customisations" :key="cust.customisationId">
                        {{ cust.text }}{{ index < item.basketItem.customisations.length - 1 ? ' | ' : '' }}
                      </span>
                    </span>
                                        <span v-if="item.basketItem.unit === 'each'">
                      {{ item.basketItem.selectedWeight }} @ {{
                                            formatCurrency(item.basketItem.price)
                                            }} {{ item.basketItem.unit }} -
                      <span
                              v-if="item.basketItem.discountAmount > 0 && item.basketItem.selectedWeight >= item.basketItem.discountQty">
                          <s>{{
                              formatCurrency(item.basketItem.selectedWeight * item.basketItem.price)
                              }}</s> {{
                          formatCurrency((item.basketItem.selectedWeight * item.basketItem.price) * ((100 - item.basketItem.discountAmount) / 100))
                          }}
                      </span>
                      <span
                              v-if="item.basketItem.discountAmount === 0 || item.basketItem.selectedWeight < item.basketItem.discountQty">
                      {{ formatCurrency(item.basketItem.selectedWeight * item.basketItem.price) }}
                      </span>
                      <br/>
                      <span v-for="(cust, index) in item.basketItem.customisations" :key="cust.customisationId">
                        {{ cust.text }}{{ index < item.basketItem.customisations.length - 1 ? ' | ' : '' }}
                      </span>
                      <span v-if="item.basketItem.discountAmount > 0">
                        <br/>
                        {{ item.basketItem.discountAmount }}% off<span v-if="item.basketItem.discountQty > 0"> when you buy {{
                          item.basketItem.discountQty
                          }} or more.</span>
                      </span>
                    </span>
                                    </p>
                                </div>
                            </div>
                            <div class="col-1" v-if="!checkout">
                                <button class="btn btn-ghost-danger mt-3" @click="setUpDelete(item)"
                                        data-bs-toggle="modal"
                                        data-bs-target="#deleteItemModal">
                                    <IconTrash></IconTrash>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-12" v-if="!checkout">
                    <div class="card">
                        <div class="card-body">
                            <h2>Estimated Total:
                                <span v-if="!hasDiscount">{{ formatCurrency(total) }}</span>
                                <span v-if="hasDiscount"><s>{{
                                    formatCurrency(total)
                                    }}</s> {{ formatCurrency(discountTotal) }}</span>
                            </h2>
                            <i class="italic">Pricing remains an estimate as weights will not be exact</i><br/>
                            <div class="mt-2 text-danger" v-if="!minimumSpend">Orders must be over £20.00</div>
                            <a class="mt-4 w-100 btn btn-primary" :class="{disabled: itemNotAvailable, disabled:!minimumSpend}" href="../checkout">Checkout</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer" v-if="checkout">
            <h2>Estimated Total:
                <span v-if="!hasDiscount">{{ formatCurrency(total) }}</span>
                <span v-if="hasDiscount"><s>{{
                    formatCurrency(total)
                    }}</s> {{ formatCurrency(discountTotal) }}</span>
            </h2>
        </div>
    </div>
    <DangerModal
            ref="modalDeleteItem"
            modalId="deleteItemModal"
            title="Remove From Basket"
            :message="deleteMessage"
            closeButton="Cancel"
            submitButton="Remove"
            @submit="handleBasketDelete"
            :errorMessage="deleteErrorMessage"
    ></DangerModal>
</template>

<script>
import {IconTrash, IconAlertCircle} from "@tabler/icons-vue"
import DangerModal from "@/components/DangerModal.vue"
import api from "@/services/api";

export default {
    name: "BasketComponent",
    components: {
        IconTrash,
        IconAlertCircle,
        DangerModal
    },
    props: {
        checkout: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            basket: [],
            deleteMessage: "",
            itemToDelete: null,
            deleteErrorMessage: "",
            hasDiscount: false,
            total: 0,
            discountTotal: 0,
            itemNotAvailable: false,
            minimumSpend: true
        }
    },
    async mounted() {
        await this.getBasket();
    },
    methods: {
        formatCurrency(amountInPence) {
            const pounds = amountInPence / 100;
            return new Intl.NumberFormat('en-GB', {style: 'currency', currency: 'GBP'}).format(pounds);
        },
        setUpDelete(item) {
            this.itemToDelete = item.basketItemId
            this.deleteMessage = `Are you sure you want to remove "${item.basketItem.item}" from your basket?`
        },
        async getBasket() {
            let basketId = localStorage.getItem('basketId');

            if (!basketId) {
                try {
                    const response = await api.get('/api/basket/new');
                    basketId = response.data.basketId;
                    localStorage.setItem('basketId', basketId);
                } catch (error) {
                    console.error('Error fetching new basketId:', error);
                    return;
                }
            }
            const basketRes = await api.get('/api/basket/checkout/' + basketId)
            this.basket = basketRes.data;
            let total = 0;
            let discountTotal = 0;
            let hasDiscount = false;
            let unavailable = false;
            this.basket.forEach(function (item) {
                if (item.basketItem.discountAmount > 0 && item.basketItem.discountQty <= item.basketItem.selectedWeight) {
                    hasDiscount = true;
                }
                total += item.basketItem.price * item.basketItem.selectedWeight;
                discountTotal += (item.basketItem.price * item.basketItem.selectedWeight) * ((100 - item.basketItem.discountAmount) / 100)
                console.log()
                if(!item.isAvailable){
                    unavailable = true;
                }
            });
            this.itemNotAvailable = unavailable;
            this.hasDiscount = hasDiscount;
            this.total = total;
            if(this.total < 2000) {
                this.minimumSpend = false;
            }
            this.discountTotal = discountTotal;
        },
        async handleBasketDelete() {
            try {
                await api.delete('/api/basket/' + this.itemToDelete);
                await this.getBasket()
                this.$refs.modalDeleteItem.$refs.closeButton.click();
                this.deleteMessage = "";
                this.itemToDelete = null;
                this.deleteErrorMessage = "";
            } catch (e) {
                this.deleteErrorMessage = e.message
            }
        }
    }
}
</script>