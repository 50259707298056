<template>
    <BasePage>
        <div class="container">
            <div class="row">
                <div class="col-sm-12 col-md-3">
                    <BasketComponent :checkout="true"></BasketComponent>
                </div>
                <div class="col">
                    <checkout-component></checkout-component>
                </div>
            </div>

        </div>
    </BasePage>
</template>

<script>
import BasketComponent from "@/components/BasketComponent.vue";


import BasePage from '@/components/BasePage.vue';
import CheckoutComponent from "@/components/CheckoutComponent.vue";

export default {
    name: 'CheckoutPage',
    components: {
        CheckoutComponent,
      BasketComponent,
        BasePage,
    },
    mounted() {
        document.title = "Checkout | Larkhall Butchers"
    }
};
</script>
