<template>
    <div class="card mt-4 mb-6">
        <div class="card-header">
            <div class="card-title">
                <ol class="breadcrumb breadcrumb-arrows" aria-label="breadcrumbs">
                    <li class="breadcrumb-item"><a href="/">Home</a></li>
                    <li class="breadcrumb-item"><a :href="'/products/'+this.$route.params.groupId">{{
                        categoryName
                        }}</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page"><a href="#">{{ product.name }}</a></li>
                </ol>
            </div>
            <div class="card-options">
            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-sm-12 col-md-6">
                    <div class="card mb-sm-3">
                        <div class="card-body">
                            <img :src="generateImage(product)" width="640" :alt="product.name" />
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 mt-md-0">
                    <div class="card mt-sm-6 mt-md-0 h-100">
                        <div class="card-body">
                            <div class="alert alert-info mb-2" role="alert" v-if="product.status">
                                <div class="d-flex">
                                    <div>
                                        <!-- Download SVG icon from http://tabler-icons.io/i/info-circle -->
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon alert-icon" width="24"
                                             height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                             fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
                                            <path d="M12 9h.01"></path>
                                            <path d="M11 12h1v4h1"></path>
                                        </svg>
                                    </div>
                                    <div>
                                        {{ product.status }}
                                    </div>
                                </div>
                            </div>
                            <h2>{{ product.name }}</h2>
                            {{ formatCurrency(product.price) }}/{{ product.weightUnit }}<br/>
                            <span class="text-blue"
                                  v-if="product.discountAmount > 0">{{ product.discountAmount }}% Off</span><span
                                class="text-blue" v-if="product.discountQty > 0"> when you buy {{ product.discountQty }} or more</span>
                            <div class="mt-2 mb-3 card-body markdown" v-html="productDescription"></div>
                            <div class="mb-3 bold" v-if="product.allergens">Allergen information:
                                {{ product.allergens }}
                            </div>
                            <div class="mb-3 bold" v-if="product.itemDeposit">A non-transferable, non-refundable deposit
                                of {{ formatCurrency(product.itemDeposit) }} applies to this product.
                            </div>
                            <span class="mt-2 mb-2 btn btn-ghost-primary d-inline-block" style="width: auto" v-if="hasSizeGuide" data-bs-toggle="modal" data-bs-target="#sizeGuideModal"><IconInfoCircle></IconInfoCircle> Size Guide</span>
                            <div class="mb-3 row">
                                <div class="col col-sm-12 col-md-6">
                                    <label for="weight-select" class="form-label" v-if="product.weightUnit !== 'each'">Select
                                        Weight</label>
                                    <label for="weight-select" class="form-label" v-if="product.weightUnit === 'each'">Select
                                        Quantity</label>
                                    <select class="form-select" :class="{'is-invalid': !validWeight}" id="weight-select"
                                            v-model="selectedWeight">
                                        <option v-for="option in product.weightOptions" :key="option.weightOptionId"
                                                :value="option.value"
                                                :disabled="option.qty === 0 || option.unavailable">
                                            {{ option.name }} <span v-if="option.unavailable">OUT OF STOCK</span>
                                        </option>
                                    </select>

                                </div>
                                <div class="col col-sm-12 col-md-6">
                                    <label class="form-label" v-if="product.weightUnit !== 'each'">Estimated
                                        Cost:</label>
                                    <label class="form-label" v-if="product.weightUnit === 'each'">Cost:</label>
                                    <h3 class="mt-1"
                                        v-if="product.discountAmount > 0 && this.selectedWeight >= product.discountQty">
                                        <s>{{ formatCurrency(this.selectedWeight * product.price) }}</s>
                                        {{ formatCurrency((this.selectedWeight * product.price) * ((100 - product.discountAmount) / 100)) }}
                                    </h3>
                                    <h3 class="mt-1"
                                        v-if="product.discountAmount === 0 || this.selectedWeight < product.discountQty">
                                        {{ formatCurrency((this.selectedWeight * product.price)) }}
                                    </h3>
                                </div>
                            </div>
                            <div class="mb-3 row" v-if="hasCustomisationOptions">
                                <div class="accordion" id="customisationOptionsAccordion">
                                    <div class="accordion-item p-2">
                                        <h2 class="accordion-header" id="customisationOptionsHeading">
                                            <button
                                                    class="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#customisationOptionsCollapse"
                                                    aria-expanded="true"
                                                    aria-controls="customisationOptionsCollapse"
                                            >
                                                Customisation Options
                                            </button>
                                        </h2>
                                        <div
                                                id="customisationOptionsCollapse"
                                                class="accordion-collapse collapse"
                                                aria-labelledby="customisationOptionsHeading"
                                                data-bs-parent="#customisationOptionsAccordion"
                                        >
                                            <!-- Nested Accordions -->
                                            <div
                                                    v-for="(customisation, index) in product.customisations"
                                                    :key="customisation.customisationId"
                                                    class="accordion collapsed"
                                                    :id="'customisationAccordion' + index"
                                            >
                                                <div class="accordion-item mb-3">
                                                    <h2 class="accordion-header" :id="'customisationHeading' + index">
                                                        <button
                                                                class="accordion-button"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                :data-bs-target="'#customisationCollapse' + index"
                                                                aria-expanded="true"
                                                                :aria-controls="'customisationCollapse' + index"
                                                        >
                                                            {{ customisation.customisationName }}
                                                        </button>
                                                    </h2>
                                                    <div
                                                            :id="'customisationCollapse' + index"
                                                            class="accordion-collapse collapse"
                                                            :aria-labelledby="'customisationHeading' + index"
                                                            :data-bs-parent="'#customisationAccordion' + index"
                                                    >
                                                        <div class="accordion-body">
                                                            {{ customisation.customisationText }}
                                                            <select class="form-select"
                                                                    v-model="selectedOptions[customisation.customisationId]">
                                                                <option
                                                                        v-for="option in customisation.customisationOptions"
                                                                        :key="option.optionID"
                                                                        :value="option.optionID"
                                                                >
                                                                    {{ option.customisationOptionName }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="d-flex align-right">
                                <div class="alert alert-success alert-dismissible w-100 mt-3 me-4" role="alert"
                                     v-if="showBasketAlert">
                                    <div class="d-flex">
                                        <div>
                                            <!-- Download SVG icon from http://tabler-icons.io/i/check -->
                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon alert-icon" width="24"
                                                 height="24"
                                                 viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                                 stroke-linecap="round"
                                                 stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M5 12l5 5l10 -10"></path>
                                            </svg>
                                        </div>
                                        <div>
                                            Added to basket
                                        </div>
                                    </div>
                                    <a class="btn-close" @click="closeBasketAlert()" aria-label="close"></a>
                                </div>
                                <div class="btn mt-2 ms-auto btn-primary" @click="addToBasket()">Add to basket</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="sizeGuideModal" class="modal modal-blur fade">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <div class="modal-status bg-primary"></div>
                <div class="modal-body">
                    <h3>Size Guide</h3>
                    <div class="table table-responsive w-100" v-html="sizeGuide">

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import api from "@/services/api";
import {IconInfoCircle} from '@tabler/icons-vue'
import DOMPurify from "dompurify";
import MarkdownIt from 'markdown-it';
const md = new MarkdownIt();

export default {
    name: "ProductDetails",
    methods: {
        formatCurrency(amountInPence) {
            const pounds = amountInPence / 100;
            return new Intl.NumberFormat('en-GB', {style: 'currency', currency: 'GBP'}).format(pounds);
        },
        generateImage(item) {
          return `https://larkhallbutchers.co.uk/cdn-cgi/image/width=640/${item.productImage}`
        },
        closeBasketAlert() {
            this.showBasketAlert = false;
        },
        async addToBasket() {
            let basketId = localStorage.getItem('basketId');

            if (!basketId) {
                try {
                    const response = await api.get('/api/basket/new');
                    basketId = response.data.basketId;
                    localStorage.setItem('basketId', basketId);
                } catch (error) {
                    console.error('Error fetching new basketId:', error);
                    return;
                }
            }

            if (this.selectedWeight) {

                const basketItem = {
                    item: this.product.name,
                    sku: this.product.sku,
                    size: this.product.selectedWeight,
                    unit: this.product.weightUnit,
                    price: this.product.price,
                    deposit: this.product.itemDeposit,
                    discountQty: this.product.discountQty,
                    discountAmount: this.product.discountAmount,
                    image: this.product.productImage,
                    selectedWeight: this.selectedWeight,
                    id: this.product.productID,
                    customisations: [],
                }
                for (const c of this.product.customisations) {
                    const x = c.customisationOptions.find(o => o.optionID === this.selectedOptions[c.customisationId])
                    console.log(x)
                    basketItem.customisations.push({
                        name: c.customisationName,
                        value: x.customisationValue,
                        text: x.customisationOptionName,
                        id: x.optionID,
                        customisationId: c.customisationId
                    })
                }
                console.log(basketItem)
                try {
                    await api.post('/api/basket', {basketGUID: basketId, basketItem})
                } catch (e) {
                    console.log(e.message)
                }
                this.showBasketAlert = true;
                this.validWeight = true;
            } else {
                this.validWeight = false;
            }
        }
    },
    async mounted() {
        try {
            this.product = (await api.get('/api/products/sku/' + this.$route.params.productId)).data
            if(this.product.sizeGuide >= 0) {
                this.hasSizeGuide = true;
                this.rawSizeGuide = (await api.get('/api/products/size-guide/'+this.product.sizeGuide)).data;
            }
            this.product.customisations.forEach(customisation => {
                const defaultOption = customisation.customisationOptions.find(option => option.customisationDefault === 1);
                if (defaultOption) {
                    this.selectedOptions[customisation.customisationId] = defaultOption.optionID;
                }
            });
            console.log(this.selectedOptions)
        } catch (e) {
            console.log(e.message)
        }
    },
    components: {
        IconInfoCircle
    },
    computed: {
        productDescription() {
            let prdouctDescription = ""
            try {
                prdouctDescription = this.product.description.toString()
            } catch (e) {
                console.log(e)
            }
            return DOMPurify.sanitize(md.render(prdouctDescription))
        },
        sizeGuide() {
          let sizeGuide = ""
            try {
              sizeGuide = this.rawSizeGuide.toString()
            } catch (e) {
                console.log(e);
            }
            return DOMPurify.sanitize(md.render(sizeGuide))
        },
        hasCustomisationOptions() {
            try {
                return this.product.customisations.some(
                    customisation => customisation.customisationOptions && customisation.customisationOptions.length > 0
                );
            } catch (err) {
                console.log("No customisations")
                return false
            }
        }

    },
    data() {
        return {
            categoryName: this.$route.params.groupId.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ').replace(/And/g, "&"),
            selectedWeight: null,
            selectedOptions: {},
            product: {},
            validWeight: true,
            showBasketAlert: false,
            hasSizeGuide: false,
            rawSizeGuide: ""
        }
    },
    watch: {
        selectedWeight() {
            if (this.selectedWeight) {
                this.validWeight = true;
            }
        }
    }
}
</script>



