<template>
  <div class="card mt-5 mb-8">
    <div class="card-header">
      <h3 class="card-title">Christmas Order</h3>
      <div class="card-options">
        Deposit Amount: {{ formatCurrency(2000) }}
      </div>
    </div>
    <div class="card-body">
      <form @submit.prevent="submitForm">
        <div class="row">
          <div class="col-lg-6">
            <div class="mb-3">
              <label class="form-label">Firstname</label>
              <input :class="validation.firstname" type="text" class="form-control" v-model="firstname">
            </div>

          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label class="form-label">Lastname</label>
              <input :class="validation.lastname" type="text" class="form-control" v-model="lastname">
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label class="form-label">Email</label>
              <input :class="validation.email" type="email" class="form-control" v-model="email">
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label class="form-label">Phone No.</label>
              <input :class="validation.phone" type="tel" class="form-control" v-model="phone">
            </div>
          </div>
          <div class="row mb-3">
            <hr class="my-5">
            <div class="col"></div>
            <div class="btn-group mt-3 col-md-8">
              <button
                  type="button"
                  class="btn btn-outline-primary"
                  :class="{ active: selectedOption === 'Collection' }"
                  @click="selectedOption = 'Collection'">Collection
              </button>

              <button
                      type="button"
                      class="btn btn-outline-primary"
                      :class="{ active: selectedOption === 'Delivery' }"
                      @click="selectedOption = 'Delivery'">Delivery (£10.00)
              </button>
            </div>
            <div class="col"></div>
          </div>
          <div v-if="selectedOption === 'Collection'">
            Collection:
            <div class="alert alert-info" v-if="selectedDate && getSelectedDateMessage()">
              {{ getSelectedDateMessage() }}
            </div>
            <div class="mb-3 mt-3 row">
              <div class="col-md-4">
                <label class="form-label">Collection Location</label>
                <select
                    :class="validation.selectedLocation"
                    class="form-select"
                    v-model="selectedLocation"
                >
                  <option
                      :disabled="!location.enabled"
                      v-for="location in collectionLocations"
                      :key="location.collectionValue"
                      :value="location.collectionLocation"
                  >
                    {{ location.collectionLocation }} - {{ formatCurrency(location.collectionFee) }}
                    <span v-if="!location.enabled">(Not Available)</span>
                  </option>
                </select>
              </div>
              <div class="col-md-4">
                <label class="form-label">Collection Date</label>
                <select
                    :class="validation.selectedDate"
                    class="form-select"
                    v-model="selectedDate"
                    :disabled="!selectedLocation"
                >
                  <option
                      :disabled="!option.enabled"
                      v-for="option in filteredDates"
                      :key="option.collectionOptionId"
                      :value="option.collectionDate"
                  >
                    {{ option.collectionDate }}
                    <span v-if="!option.enabled">(Not Available)</span>
                  </option>
                </select>
              </div>
              <div class="col-md-4">
                <label class="form-label">Collection Time</label>
                <select
                    :class="validation.selectedTime"
                    class="form-select"
                    v-model="selectedTime"
                    :disabled="!selectedDate"
                >
                  <option
                      v-for="option in filteredTimes"
                      :key="option.collectionSlotId"
                      :value="option.collectionTime"
                  >
                    {{ option.collectionTime }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div v-if="selectedOption === 'Delivery'">
            <!-- Delivery Date Dropdown -->
            Delivery:
            <div class="row mt-3 mb-3">
              <div class="col-md-4">
                <label class="form-label">Delivery Date</label>
                <select :class="validation.selectedDeliveryDate" class="form-select"
                        v-model="selectedDeliveryDate">
                  <option v-for="option in deliveryDates" :key="option.deliveryValue"
                          :value="option.deliveryDate">
                    {{ option.deliveryDate }}
                  </option>
                </select>
              </div>

              <div class="col-md-4">
                <!-- UK Postcode Input -->
                <label class="form-label">Postcode</label>
                <div class="input-group">
                  <input :class="validation.postcode" type="text" class="form-control" v-uk-postcode
                         v-model="postcode">
                  <button class="btn btn-outline-primary" type="button" @click="searchPostcode">Search
                  </button>
                </div>
              </div>
              <!-- Address Dropdown -->
              <div class="col-md-4">
                <div class="mb-3">
                  <label class="form-label">Select Address</label>
                  <select :class="validation.selectedAddress" class="form-select"
                          v-model="selectedAddress" :disabled="!addresses.length">
                    <option v-for="address in addresses" :key="address.id" :value="address.address">
                      {{ address.address }}
                    </option>
                  </select>
                  <p class="link-azure mt-1 cursor-pointer" v-if="hasSearchedPostcode"
                     @click="showManualInput">
                    My address isn't listed
                  </p>
                </div>
              </div>
            </div>
            <label v-if="showManualAddressInput" class="form-label">Enter your address:</label>
            <textarea :class="validation.manualAddress" v-if="showManualAddressInput"
                      v-model="manualAddress" class="form-control mt-2"
                      rows="3" placeholder="Enter your address manually..."></textarea>
          </div>

        </div>
        <hr class="my-5">
        <div>
          <label class="form-label">Comments or allergy requirements</label>
          <textarea v-model="comments" class="form-control mt-2" rows="3"
                    placeholder="Please let us know any comments or Comments or allergy requirements you might have"></textarea>
        </div>

        <hr class="my-5">
        <div class="row">
          <h3>Jamie's Farm Charity Donation</h3>
          <div class="row">
            <div class="col-md-3">
              <img src="https://static.larkhallbutchers.co.uk/jamies_farm.svg">

            </div>
            <div class="col">
              We have been working proudly and exclusively with Jamie’s Farm for several years, getting
              our pork and lamb from them.
              Jamie’s Farm is a fantastic charity with a programme that focuses on improving behavior,
              engagement, wellbeing, and essential life skills for young people. They offer a unique,
              preventative solution which empowers youths to choose the best paths for their futures.
              This year, so that they can continue the fantastic work they do with young people, we have
              decided to raise some money for Jamie's Farm and we hope that you can help.
              <br/>
              Jamie’s Farm Registered Charity: 1129544<br/>
              <a href="https://jamiesfarm.org.uk/about/" target="_blank">jamiesfarm.org.uk/about</a>
              <br/>
              <div class="mt-3  btn-group" style="flex-wrap: wrap;">
                <div v-for="option in donationOptions" :key="option.value"
                     :class="['btn', selectedDonation === option.value ? 'btn-primary' : 'btn-outline-primary']"
                     @click="setDonation(option.value)">
                  {{ option.label }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr class="my-5">
        <div class="mb-3 mt-6 text-end">
          <div class="d-inline-block text-start me-3">
            <label class="form-check">
              <input class="form-check-input" type="checkbox" v-model="mailingList">
              <span class="form-check-label">Join our mailing list for promotions and updates</span>
            </label>
          </div>
          <br/>
          <button :disabled="isCheckingOut" type="submit" class="btn btn-primary">Go to payment</button>
        </div>
      </form>
    </div>
    <button style="display: none" ref="triggerModal" data-bs-toggle="modal"
            data-bs-target="#checkoutErrorModal"></button>
  </div>
  <div class="mt-6">
    <div @click="showStaff = true" class="btn-ghost-muted btn cursor-pointer">Staff</div>
    <div v-if="showStaff"><input v-model="staffId" type="text" class="form-control" placeholder="Staff ID"></div>
  </div>
  <DangerModal
      ref="checkoutErrorModal"
      modalId="checkoutErrorModal"
      title="Error"
      :message="errorMessage"
      closeButton="Cancel"
      submitButton="OK"
      @submit="handleErrorOk"
      :errorMessage="''"
  ></DangerModal>
</template>
<script>
import api from "@/services/api";
import DangerModal from "@/components/DangerModal.vue";

export default {
  name: "CheckoutComponent",
  components: {DangerModal},
  data() {
    return {
      showStaff: false,
      staffId: null,
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      selectedLocation: null,
      selectedDate: null,
      selectedTime: null,
      collectionLocations: [],
      collectionOptions: [],
      collectionSlots: [],
      selectedOption: 'Collection',
      selectedDeliveryDate: null,
      deliveryDates: [],
      postcode: "",
      addresses: [],
      selectedAddress: null,
      hasSearchedPostcode: false,
      showManualAddressInput: false,
      manualAddress: '',
      mailingList: false,
      comments: '',
      donationOptions: [
        {label: 'No Donation', value: 0},
        {label: '£1 Donation', value: 1},
        {label: '£2 Donation', value: 2},
        {label: '£5 Donation', value: 5},
        {label: '£10 Donation', value: 10},
        {label: '£20 Donation', value: 20}
      ],
      errorMessage: "",
      selectedDonation: 0,
      isCheckingOut: false,
      validation: {
        firstname: null,
        lastname: null,
        email: null,
        phone: null,
        selectedLocation: null,
        selectedDate: null,
        selectedTime: null,
        selectedDeliveryDate: null,
        postcode: null,
        selectedAddress: null,
        manualAddress: null,
      },

    }
  },
  async mounted() {
    try {
      const optionsResponse = await api.get('/api/collection/options');
      const tempObj = optionsResponse.data.reduce((acc, opt) => {
        acc[opt.collectionLocation] = {
          collectionLocation: opt.collectionLocation,
          collectionFee: opt.collectionFee,
          collectionValue: opt.collectionValue,
          collectionMessage: opt.collectionMessage,
          enabled: false
        };
        return acc;
      }, {});

      this.collectionLocations = Object.values(tempObj);

      this.collectionOptions = optionsResponse.data;

      for (const o of this.collectionOptions) {
        if (o.enabled) {
          const i = this.collectionLocations.findIndex(x => x.collectionLocation === o.collectionLocation)
          this.collectionLocations[i].enabled = true;
        }
      }

      const slotsResponse = await api.get('/api/collection/slots');
      this.collectionSlots = slotsResponse.data;
      const deliveryDatesResponse = await api.get('/api/collection/delivery');
      this.deliveryDates = deliveryDatesResponse.data;
    } catch (e) {
      console.log(e.message)
    }
  },
  computed: {
    filteredDates() {
      return this.collectionOptions.filter(opt =>
          opt.collectionLocation === this.selectedLocation
      );
    },
    filteredTimes() {
      const selectedOption = this.collectionOptions.find(opt => opt.collectionDate === this.selectedDate && opt.collectionLocation === this.selectedLocation);
      if (selectedOption) {
        return this.collectionSlots.filter(slot => slot.collectionOption === selectedOption.collectionOptionId &&
            (slot.bookedCollections < slot.capacity));
      }
      return [];
    },
  },
  methods: {
    async searchPostcode() {
      try {
        const cleanPostcode = this.postcode.replace(/\s+/g, '');
        const addressResponse = await api.get(`https://location-service.larkhall-butchers.workers.dev/?postcode=${cleanPostcode}`);
        this.addresses = addressResponse.data.addresses;
        this.hasSearchedPostcode = true;
      } catch (e) {
        //console.log(e)
        this.errorMessage = (e.response.data);
        this.$refs.triggerModal.click()
        //console.log(e.message);
      }
    },
    getSelectedDateMessage() {
      if (this.selectedDate) {
        const selectedDateObj = this.filteredDates.find(
            date => date.collectionDate === this.selectedDate
        );
        return selectedDateObj && selectedDateObj.collectionMessage
            ? selectedDateObj.collectionMessage
            : null;
      }
      return null;
    },
    setDonation(value) {
      this.selectedDonation = value;
    },
    showManualInput() {
      this.selectedAddress = ""
      this.showManualAddressInput = true;
    },
    formatCurrency(amountInPence) {
      const pounds = amountInPence / 100;
      return new Intl.NumberFormat('en-GB', {style: 'currency', currency: 'GBP'}).format(pounds);
    },
    handleErrorOk() {
      this.isCheckingOut = false;
      this.$refs.checkoutErrorModal.$refs.closeButton.click();
    },
    async submitForm() {
      if (!this.isCheckingOut) {
        if (this.validateForm()) {
          this.isCheckingOut = true;
          const payload = {
            staffId: this.staffId,
            firstname: this.firstname,
            lastname: this.lastname,
            email: this.email,
            phone: this.phone,
            selectedOption: this.selectedOption,
            donation: this.selectedDonation,
            basketId: localStorage.getItem('basketId'),
            mailingList: this.mailingList,
            comments: this.comments
          };

          if (this.selectedOption === 'Collection') {
            payload.collectionDetails = {
              selectedLocation: this.selectedLocation,
              selectedDate: this.selectedDate,
              selectedTime: this.selectedTime,
            };
          } else {
            payload.deliveryDetails = {
              selectedDeliveryDate: this.selectedDeliveryDate,
              postcode: this.postcode,
              address: this.selectedAddress || this.manualAddress,
            };
          }

          try {
            const paymentRes = await api.post('/api/checkout/payment', payload);
            const paymentApi = paymentRes.data;
            if (paymentApi.URL) {
              window.location.href = paymentApi.URL;
            }
            // Handle successful response, e.g., navigate to a different page
          } catch (e) {
            console.error(e.message);
            this.errorMessage = ("An error has occurred contacting our payment provider, please try again.");
            this.$refs.triggerModal.click()
          }
        } else {
          this.errorMessage = ("Please ensure you've entered all required information");
          this.$refs.triggerModal.click()
          // Handle validation failure, e.g., show error message to the user
        }
      }
    },
    validateForm() {
      let isValid = true;

      // Reset validation object
      this.validation = {
        firstname: null,
        lastname: null,
        email: null,
        phone: null,
        selectedLocation: null,
        selectedDate: null,
        selectedTime: null,
        selectedDeliveryDate: null,
        postcode: null,
        selectedAddress: null,
        manualAddress: null,
      };

      const requiredFields = ['firstname', 'lastname', 'email', 'phone'];

      // Check if any required field is empty
      requiredFields.forEach(field => {
        if (!this[field]) {
          this.validation[field] = 'is-invalid';
          isValid = false;
        }
      });

      // Validate email format
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(this.email)) {
        this.validation.email = 'is-invalid';
        isValid = false;
      }

      // Validate selected option and its related fields
      if (this.selectedOption === 'Collection') {
        if (!this.selectedLocation || !this.selectedDate || !this.selectedTime) {
          this.validation.selectedLocation = this.validation.selectedLocation || 'is-invalid';
          this.validation.selectedDate = this.validation.selectedDate || 'is-invalid';
          this.validation.selectedTime = this.validation.selectedTime || 'is-invalid';
          isValid = false;
        }
      } else if (this.selectedOption === 'Delivery') {
        if (!this.selectedDeliveryDate || !this.postcode || !(this.selectedAddress || this.manualAddress)) {
          this.validation.selectedDeliveryDate = this.validation.selectedDeliveryDate || 'is-invalid';
          this.validation.postcode = this.validation.postcode || 'is-invalid';
          this.validation.selectedAddress = this.validation.selectedAddress || 'is-invalid';
          this.validation.manualAddress = this.validation.manualAddress || 'is-invalid';
          isValid = false;
        }
      }

      return isValid;
    },
  }
}
</script>