import { createApp } from 'vue'
import App from './App.vue'
import router from './router';



import '@tabler/core/dist/css/tabler.min.css';
import '@tabler/core/dist/js/tabler.min.js';

const ukPostcodeDirective = {
    beforeMount(el) {
        function handleInput(e) {
            let input = e.target.value;
            input = input.toUpperCase();
            input = input.replace(/[^A-Z0-9]/g, '');

            let outward, inward;

            if (input.length > 3) {
                inward = input.slice(-3);
                outward = input.slice(0, input.length - 3);

                // Add space between outward and inward codes
                e.target.value = `${outward} ${inward}`;
            } else {
                e.target.value = input;
            }
        }

        el.addEventListener('input', handleInput);
        el._handleInput = handleInput;
    },
    unmounted(el) {
        el.removeEventListener('input', el._handleInput);
    },
};
/*
if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/service-worker.js')
        .then(registration => {
            console.log('Service Worker registered with scope:', registration.scope);
        })
        .catch(error => {
            console.error('Service Worker registration failed:', error);
        });
}*/

createApp(App).use(router).directive('uk-postcode',ukPostcodeDirective).mount('#app')
