<template>
    <div class="mt-4" :class="{ 'mt-md-7': currentPage === 'meat', 'mt-sm-0': currentPage === 'meat' }">
        <div class="row">
            <div v-for="item in items" v-bind:key="item.groupId" class="col-lg-3 col-md-4 col-sm-6">
                <div class="card mb-4">
                    <img :src="generateImage(item)" class="card-img-top" :alt="'Product Image of '+item.name">
                    <div class="card-footer">
                        <router-link :to="generateLink(item)">{{ item.name }}</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "ProductTiles",
    data() {
        return {
            items: null,
            currentPage: this.$route.params.groupId,
        }
    },
    props: {
        products: {
            type: Array,
        },
        isHome: {
            type: Boolean,
            default: false
        }
    },
    methods: {
      generateLink(item) {
          if(this.isHome){
              return `/products/${item.group}/${item.sku}`
          }else {
              return `/products/${this.currentPage}/${item.sku}`
          }
      },
      generateImage(item) {
        return `https://larkhallbutchers.co.uk/cdn-cgi/image/width=640/${item.productImage}`
      }
    },
    watch: {
        products(newProducts) {
            this.items = newProducts;
        }
    }
}
</script>