<template>
    <BasePage>
        <div class="container">
            <ProductTiles :isHome="true" :products="products"></ProductTiles>
        </div>
    </BasePage>
</template>

<script>
import ProductTiles from "@/components/ProductTiles.vue";
import api from '@/services/api'

document.title = "Christmas Orders | Larkhall Butchers"
import BasePage from '@/components/BasePage.vue';  // Adjust the import path if necessary

export default {
    name: 'HomePage',
    components: {
        ProductTiles,
        BasePage,
    },
    data() {
        return {
            products: []
        }
    },
    async mounted() {
        const productsRes = await api.get('/api/products/home')
        this.products = productsRes.data;
    }
};
</script>
