<template>
    <BasePage>
        <div class="container">
          <BasketComponent></BasketComponent>
        </div>
    </BasePage>
</template>

<script>
import BasketComponent from "@/components/BasketComponent.vue";


import BasePage from '@/components/BasePage.vue';  // Adjust the import path if necessary

export default {
    name: 'BasketPage',
    components: {
      BasketComponent,
        BasePage,
    },
    mounted() {
        document.title = "Basket | Larkhall Butchers"
    }
};
</script>
