<template>
    <BasePage>
        <div class="container">
            <div class="container">
                <div v-if="!order.error" class="card mt-6">
                    <div class="card-header">
                        <div class="card-title">
                            <div>Order N<sup>o</sup> {{ order.id }} | Outstanding Balance</div>
                        </div>
                    </div>
                    <div class="card-body" v-if="order.balanceStatus !== 'paid'">
                        Deposit paid: {{ formatGBP(order.depositAmount) }}
                    </div>
                    <div class="card-body" v-if="order.balanceStatus !== 'paid'">
                        <table class="table card-table table-bordered">
                            <thead class="table-striped-columns">
                            <th>Item</th>
                            <th>Size/Qty</th>
                            <th>Actual</th>
                            <th>Price</th>
                            </thead>
                            <tbody>
                            <tr v-bind:key="i.id" v-for="i in order.items">
                                <td>{{ i.item }}</td>
                                <td v-if="i.unit === 'each'">{{ i.size }}</td>
                                <td v-if="i.unit !== 'each'">{{ i.size }}{{ i.unit }}</td>
                                <td v-if="i.unit === 'each'">{{ i.size }}</td>
                                <td v-if="i.unit !== 'each'">{{ i.allocatedWeight }}{{ i.unit }}</td>
                                <td v-if="i.unit !== 'each'">{{ formatGBP(i.allocatedWeight * i.price) }}</td>
                                <td v-if="i.unit === 'each'">{{ formatGBP(i.size * i.price) }}</td>
                            </tr>
                            <tr style="padding-top: 10px" class="border-0">
                                <td class="border-0"></td>
                                <td class="border-0"></td>
                                <td class="border-0 border-bottom text-end">Subtotal: </td>
                                <td class="border-0 border-bottom">{{formatGBP(order.total)}}</td>
                            </tr>
                            <tr style="padding-top: 10px" class="border-0">
                                <td class="border-0"></td>
                                <td class="border-0"></td>
                                <td class="border-0 border-bottom text-end">Deposit: </td>
                                <td class="border-0 border-bottom">- {{formatGBP(order.depositAmount)}}</td>
                            </tr>
                            <tr style="padding-top: 10px" class="border-0">
                                <td class="border-0"></td>
                                <td class="border-0"></td>
                                <td class="border-0 border-bottom text-end">Remaining: </td>
                                <td class="border-0 border-bottom">{{formatGBP(order.total - order.depositAmount)}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div  v-if="order.balanceStatus === 'paid'" class="card-body">
                        You have already paid your remaining Christmas Balance.
                    </div>
                    <div class="card-footer"  v-if="order.balanceStatus !== 'paid'">
                        <div class="card-actions d-flex justify-content-end">
                            <span @click="gotToPayment()" class="btn btn-primary">Pay {{formatGBP(order.total - order.depositAmount)}} Now</span>
                        </div>
                    </div>
                </div>
                <div v-if="order.error" class="card mt-6 py-6 text-center">
                    {{ order.error }}
                </div>
            </div>
        </div>
    </BasePage>
</template>

<script>
import BasePage from "@/components/BasePage.vue";
import api from "@/services/api";

export default {
    name: 'BillingPage',
    components: {
        BasePage,
    },
    data() {
        return {
            order: {}
        }
    },
    methods: {
        formatGBP(pence) {
            const pounds = pence / 100;
            return new Intl.NumberFormat('en-GB', {
                style: 'currency',
                currency: 'GBP'
            }).format(pounds);
        },
        async gotToPayment() {
            const res = await api.post(`/api/billing/create-session/${this.$route.params.guid}`);
            window.location = res.data.url;
        }
    },
    async mounted() {
        const res = await api.get(`/api/billing/${this.$route.params.guid}`);
        this.order = res.data;
    }
}
</script>