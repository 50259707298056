<template>
    <BasePage>
        <div class="container">
            <div v-if="loggedIn" class="container">
                <div class="card mt-6" v-for="order in orders" :key="order.id">
                    <div class="card-header">
                        <h3 class="card-title">Order N<sup>o</sup> {{ order.id }}</h3>
                        <div class="card-options">
                            <div @click="logout" class="btn btn-outline-primary cursor-pointer">Log out</div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="datagrid">
                            <div class="datagrid-item">
                                <div class="datagrid-title">Collection Method</div>
                                <div class="datagrid-content">{{ order.collectionOption }}</div>
                            </div>
                            <div class="datagrid-item" v-if="order.collectionOption === 'Collection'">
                                <div class="datagrid-title">Collection Location</div>
                                <div class="datagrid-content">{{ order.collectionLocation }}</div>
                            </div>
                            <div class="datagrid-item" v-if="order.collectionOption === 'Collection'">
                                <div class="datagrid-title">Collection Date</div>
                                <div class="datagrid-content">{{ order.collectionDate }}</div>
                            </div>
                            <div class="datagrid-item" v-if="order.collectionOption === 'Collection'">
                                <div class="datagrid-title">Collection Time</div>
                                <div class="datagrid-content">{{ order.collectionTime }}</div>
                            </div>
                            <div class="datagrid-item" v-if="order.collectionOption === 'Delivery'">
                                <div class="datagrid-title">Delivery Date</div>
                                <div class="datagrid-content">{{ order.deliveryDate }}</div>
                            </div>
                            <div class="datagrid-item" v-if="order.collectionOption === 'Delivery'">
                                <div class="datagrid-title">Delivery Address</div>
                                <div class="datagrid-content">{{ order.deliveryAddress }}</div>
                            </div>
                        </div>

                        <table class="table card-table table-vcenter mt-6 mb-6">
                            <thead>
                            <tr>
                                <th>Item</th>
                                <th>Size</th>
                                <th>Extra Info</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="item in order.items" :key="item.id">
                                <td>{{ item.item }}</td>
                                <td>{{  item.size }} <span v-if="item.unit === 'kg'">kg</span></td>
                                <td>{{customisations(item.customisationsData)}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="card-footer">
                        If you are wanting to update your order, please send us an email to info@larkhallbutchers.co.uk
                    </div>
                </div>
            </div>
            <div v-if="!loggedIn" class="container mt-6 mb-7 text-center">
                <div class="row justify-content-center">
                    <div class="col-auto">
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">Log in to your orders</h3>
                            </div>
                            <div class="card-body align-left">
                                <div class="mb-3">
                                    <div v-if="emailSent" class="alert alert-success" role="alert">
                                        Email sent
                                    </div>
                                    <label class="form-label" style="text-align: left">Email address</label>
                                    <input v-model="email" type="email" class="form-control"
                                           placeholder="your@email.com" autocomplete="off">
                                </div>
                                <div class="form-footer">
                                    <div @click="submitEmail" class="btn btn-primary w-100 cursor-pointer">Sign in</div>
                                </div>
                                <div class="mt-6 italic">
                                    We will send you a magic link to your email to access your order.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </BasePage>
</template>

<script>

function decodeJwt(token) {
    try {
        const payloadSegment = token.split('.')[1];
        const base64 = payloadSegment.replace('-', '+').replace('_', '/');
        const payloadJson = atob(base64);
        const payload = JSON.parse(payloadJson);
        return {payload, exp: payload.exp};
    } catch (error) {
        console.error('Failed to decode JWT:', error);
        return null;
    }
}

import api from "@/services/api";


import BasePage from '@/components/BasePage.vue';  // Adjust the import path if necessary

export default {
    name: 'HomePage',
    components: {
        BasePage,
    },
    data() {
        return {
            loggedIn: false,
            email: "",
            orders: [],
            emailSent: false
        }
    },
    methods: {
        logout() {
            localStorage.removeItem('accountToken')
            this.loggedIn = false;
        },
        async submitEmail() {
            try {
                await api.post(`/api/orders/auth`, {email: this.email})
                this.emailSent = true;
            } catch (e) {
                console.log(e)
            }
        },
        customisations(custs) {
            let extraInfoItems = []
            for(const inf of JSON.parse(custs)){
                extraInfoItems.push(`${inf.name}: ${inf.text}`)
            }
            return  extraInfoItems.join(" | ")
        },
        async getOrders() {
            this.loggedIn = true;
            try {
                const response = await api.get('/api/orders/my-orders', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accountToken')}`
                    }
                });
                this.orders = response.data;
                //console.log(response.data)
            } catch (e) {
                console.log(e)
            }
        }
    },
    async mounted() {
        document.title = "My Order | Larkhall Butchers"
        console.log(this.$route.query.token)
        if (this.$route.query.token) {
            try {
                const getTokenRes = await api.get(`/api/orders/auth/${this.$route.query.token}`)
                console.log(getTokenRes.data)
                localStorage.setItem('accountToken', getTokenRes.data.token)
                window.location.href = '/my-order/'
                //this.loggedIn = true;
                //await this.getOrders();
            } catch (e) {
                console.log(e)
            }
        } else {
            let token = localStorage.getItem('accountToken');
            if (token) {
                const {exp} = decodeJwt(token);
                const now = Math.floor(Date.now() / 1000);
                const timeUntilExpiry = exp - now;
                if (timeUntilExpiry >= 300) {
                    this.loggedIn = true;
                    await this.getOrders();
                }
            }
            console.log(token)
        }
    }
};
</script>
