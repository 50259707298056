<template>
    <ul class="navbar-nav">
        <li class="nav-item" v-for="item in menuItems" v-bind:key="item.groupId"
            :class="{ active: isActive(item.groupSlug) }">
            <a class="nav-link" :href="'/products/' + item.groupSlug">
                        <span class="d-md-none d-lg-inline-block">
                        </span>
                <span class="nav-link-title">{{ item.groupName }}</span>
            </a>
        </li>
    </ul>
    <div class="position-relative d-md-none" ref="meatMenu" v-if="isMeat">
        <div class="p-2">
            <div class="btn mx-2 my-2" @click="setParam(item.typeSlug)" v-for="item in meatItems" v-bind:key="item.typeId"
                 :class="{ active: isTypeActive(item.typeSlug) }">
                        <span class="d-md-none d-lg-inline-block">
                        </span>
                <span class="nav-link-title">{{ item.typeName }}</span>
            </div>
        </div>
    </div>
    <div class="position-absolute d-none d-md-block" v-if="isMeat">
        <div class="p-2">
            <div class="btn mx-2 my-2" @click="setParam(item.typeSlug)" v-for="item in meatItems" v-bind:key="item.typeId"
                 :class="{ active: isTypeActive(item.typeSlug) }">
                        <span class="d-md-none d-lg-inline-block">
                        </span>
                <span class="nav-link-title">{{ item.typeName }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import api from "@/services/api"

export default {
    name: "MenuItems",
    data() {
        return {
            menuItems: [],
            currentItem: null,
            isMeat: false,
            meatItems: []
        }
    },
    methods: {
        isActive(path) {
            if (path === "/" && this.$route.path.length > 1) {
                return false;
            }
            const isActive = path === this.$route.params.groupId
            return isActive;
        },
        isTypeActive(param) {
            return param === this.currentParam
        },
        setParam(value) {
            this.$router.push({
                path: this.$route.path,
                query: {
                    ...this.$route.query,
                    type: value
                }
            });
        }
    },
    async mounted() {
        console.log(this.$route.params.groupId)
        this.menuItems = (await api.get('/api/groups')).data;
        //console.log(this.menuItems)
        try {
            this.currentItem = this.$route.params.groupId
            console.log(this.$route.params)
            this.isMeat = this.currentItem === 'meat' && !this.$route.params.productId
            if(this.isMeat) {
                this.meatItems = (await api.get('/api/types/group/'+ this.$route.params.groupId)).data
            }
            if(!this.currentParam){
                console.log(this.currentParam);
                this.setParam(this.meatItems[0].typeSlug)
            }
        } catch (e) {
            //console.log(e)
        }
    },
    computed: {
        currentParam() {
            return this.$route.query.type;
        }
    },

}
</script>